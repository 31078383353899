body {
  padding: 32px;
  background-color: #e6e6e6;
  margin: 0px;
}

.App {
  font-family: "Garamond";
  font-size: 16px;
  max-width: 750px;
  margin: 0px auto;
}

.App h1{
  text-align: center;
  letter-spacing: 2px;
  margin-top: 0;
}

.App h1 small{
  font-weight: 400;
  font-size: 0.75em;
}

.persons{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 88px 0px;
}

.imp{
  font-size: 14px;
}

.desk-hidden{
  display: none
}

.float-right{
  float: right
}

@media screen and (max-width: 670px)  {
  .persons{
    display: block;
    margin: 48px 0px;
  }

  .desk-hidden{
    display: inline
  }

  .resp-hidden{
    display: none;
  }

  .resp-margin-top{
    margin-top: 48px
  }

  .resp-border {
    border: 1px solid;
    border-radius: 4px;
    padding: 0px 8px;
    width: calc(100% - 32px)
  }

  .App h1{
    text-align: left;
    font-size: 1.5rem
  }

  .App h2{
    font-size: 1.3rem
  }

  body{
    padding: 16px 16px
  }
}